import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Grid,
  Link,
  Grow,
  Paper,
  Button,
  Popper,
  MenuList,
  MenuItem,
  // Snackbar,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
  ClickAwayListener,
} from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { BiPlus, BiFilter } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import { fetchTasks } from '../../redux/actions/taskActions';
import { MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import RyffineTable from '../common/RyffineTable';
import { formatDateStr } from '../../core/services/commonService';
import {
  TASKS_ROUTE,
  TYPE_NORMALIZATION,
  TYPE_CONVERSION,
  TYPES_FILTER,
  CREATE_TASK_ROUTE,
} from '../../core/constants';
import { filterBySearch } from '../../core/services/taskConversionService';
import { generateStatusField } from '../common/Utility';
import FiltersDialog from './FiltersDialog';
import CreateNormalizationDialog from './CreateNormalizationDialog';
import CreateConversionDialog from './CreateConversionDialog';
import CreateIxiasoftDialog from './CreateIxiasoftDialog';
import CreatePandocDialog from './CreatePandocDialog';
import CreatePaligoDialog from './CreatePaligoDialog';
import CreateSdlDialog from './CreateSdlDialog';
import {
  MAIN_UI_COLOR, ENABLED_TASKS, ENABLE_OLD_CREATE_CONVERSION, CONVERSION_TITLE,
} from '../../config';
import CreateFluidTopicsDialog from './CreateFluidTopicsDialog';
import CreateGitHubDialog from './CreateGitHubDialog';
import CreateKionSdlDialog from './CreateKionSdlDialog';
import CreateZoominDialog from './CreateZoominTaskDialog';

const generateTableRows = tasks => (
  tasks
    .map((task, index) => ({
      id: index,
      uuid: {
        value: task.id,
        displayValue: <Link to={`${TASKS_ROUTE}/${task.id}`} component={RouterLink}>{task.id}</Link>,
      },
      source: task.source,
      converter: task.converter,
      tag: task.tag,
      status: {
        displayValue: generateStatusField(task.status),
        value: task.status,
      },
      owner: task.owner,
      created: {
        displayValue: formatDateStr(task.created) || 'N/A',
        value: task.created,
      },
    }))
);

const columns = [
  {
    field: 'uuid', headerName: 'UUID', sortable: true, flex: 1,
  },
  {
    field: 'converter', headerName: 'Type', sortable: true, flex: 0.5,
  },
  {
    field: 'owner', headerName: 'Owner', sortable: true, flex: 0.5,
  },
  {
    field: 'tag', headerName: 'Release', sortable: true, flex: 0.5,
  },
  {
    field: 'status', headerName: 'Status', sortable: true, flex: 0.5,
  },
  {
    field: 'created', headerName: 'Date created', sortable: true, flex: 0.75,
  },
];

const TASKS_SEARCH_STATE = 'tasks-search-state';
const TASKS_FITLER_STATE = 'tasks-filter-state';

function Tasks() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState(localStorage.getItem(TASKS_SEARCH_STATE) || '');

  const { tasks, loading } = useSelector(state => state.task);

  const [pageLoading, setPageLoading] = useState(true);

  const isMobile = useSelector(state => state.app.isMobile);
  const isMenuOpen = useSelector(state => state.app.isMenuOpen);

  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem(TASKS_FITLER_STATE) || '{}'));

  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);

  const [openSdlTaskDialog, setOpenSdlTaskDialog] = useState(false);
  const [openKionSdlTaskDialog, setOpenKionSdlTaskDialog] = useState(false);

  const [openZoominTaskDialog, setOpenZoominTaskDialog] = useState(false);
  const [openPandocTaskDialog, setOpenPandocTaskDialog] = useState(false);
  const [openPaligoTaskDialog, setOpenPaligoTaskDialog] = useState(false);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openGitHubTaskDialog, setOpenGitHubTaskDialog] = useState(false);
  const [openIxiasoftTaskDialog, setOpenIxiasoftTaskDialog] = useState(false);
  const [openFluidTopicsTaskDialog, setOpenFluidTopicsTaskDialog] = useState(false);
  const [openNormalizationTaskDialog, setOpenNormalizationTaskDialog] = useState(false);

  // const [createTaskSnackbarLink, setCreateTaskSnackbarLink] = useState(undefined);
  // const [createTaskSnackbarOpen, setCreateTaskSnackbarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTasks()).finally(() => setPageLoading(false));

    // clear state in case when page will be reloaded
    history.replace();
  }, [dispatch]);

  useEffect(() => localStorage.setItem(TASKS_SEARCH_STATE, searchValue), [searchValue]);

  useEffect(() => {
    if (!pageLoading) localStorage.setItem(TASKS_FITLER_STATE, JSON.stringify(filters));
  }, [filters]);

  const filteredTasks = useMemo(
    () => filterBySearch(tasks, searchValue, filters),
    [tasks, searchValue, filters],
  );

  const tableRows = useMemo(() => generateTableRows(filteredTasks), [filteredTasks]);

  const onClickSelectType = type => {
    const obj = { ...filters };

    if (obj.types) {
      if (obj.types.indexOf(type) === -1) obj.types.push(type);
      else {
        obj.types.splice(obj.types.indexOf(type), 1);

        switch (type) {
          case TYPE_NORMALIZATION:
            if (Object.keys(obj).includes('normalizationTag')) delete obj.normalizationTag;
            break;

          case TYPE_CONVERSION:
            if (Object.keys(obj).includes('conversionType')) delete obj.conversionType;
            if (Object.keys(obj).includes('conversionTag')) delete obj.conversionTag;
            break;

          default:
            break;
        }
      }
    } else obj.types = [type];

    setFilters(obj);
  };

  const filtersLength = Object.keys(filters).filter(k => k !== 'types').length || 0;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCreationForm = (changeValueMethod, link) => {
    changeValueMethod(false);

    if (link) history.push(link);
  };

  return (
    <>
      <div style={{
        borderBottom: '1px solid rgba(51, 51, 51, 0.3)',
        paddingBottom: '20px',
        paddingRight: '60px',
        paddingLeft: '60px',
        paddingTop: '48px',
        background: 'white',
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography
              variant="h4"
              style={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
              )}
              Tasks
            </Typography>
          </Grid>

          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              onClick={() => setOpenFiltersDialog(true)}
              variant="outlined"
              className="button"
              color="primary"
            >
              <IconButton className="outlined-button-icon"><BiFilter /></IconButton>
              Filters
            </Button>

            <Button
              onClick={() => setOpen(prevOpen => !prevOpen)}
              style={{ marginLeft: '16px' }}
              variant="contained"
              className="button"
              color="primary"
              ref={anchorRef}
            >
              <IconButton className="contained-button-icon"><BiPlus /></IconButton>
              Create Task
            </Button>

            <Popper
              style={{ zIndex: 10 }}
              anchorEl={anchorRef.current}
              role={undefined}
              disablePortal
              open={open}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList key="profileList" autoFocusItem={open} id="menu-list-grow">
                        {ENABLED_TASKS.conversion && (
                          <>
                            {ENABLE_OLD_CREATE_CONVERSION && (
                              <MenuItem key="conversion" onClick={() => history.push(CREATE_TASK_ROUTE)}>{CONVERSION_TITLE}</MenuItem>
                            )}

                            {!ENABLE_OLD_CREATE_CONVERSION && (
                              <MenuItem onClick={() => setOpenCreateTaskDialog(true)} key="conversion">
                                {CONVERSION_TITLE}
                              </MenuItem>
                            )}
                          </>
                        )}

                        {ENABLED_TASKS.normalization && (
                          <MenuItem key="normalization" onClick={() => setOpenNormalizationTaskDialog(true)}>Normalization</MenuItem>
                        )}

                        {ENABLED_TASKS.pandoc && (
                          <MenuItem key="pandoc" onClick={() => setOpenPandocTaskDialog(true)}>Pandoc</MenuItem>
                        )}

                        {ENABLED_TASKS.ixiasoft && (
                          <MenuItem key="ixiasoft" onClick={() => setOpenIxiasoftTaskDialog(true)}>Ixiasoft</MenuItem>
                        )}

                        {ENABLED_TASKS.paligo && (
                          <MenuItem key="paligo" onClick={() => setOpenPaligoTaskDialog(true)}>Paligo</MenuItem>
                        )}

                        {ENABLED_TASKS.kion_sdl && (
                          <MenuItem key="kion-sdl" onClick={() => setOpenKionSdlTaskDialog(true)}>Kion Tridion</MenuItem>
                        )}

                        {ENABLED_TASKS.sdl && (
                          <MenuItem key="sdl" onClick={() => setOpenSdlTaskDialog(true)}>Tridion</MenuItem>
                        )}

                        {ENABLED_TASKS.fluidTopics && (
                          <MenuItem key="fluid-topics" onClick={() => setOpenFluidTopicsTaskDialog(true)}>
                            Fluid Topics
                          </MenuItem>
                        )}

                        {ENABLED_TASKS.zoomin && (
                          <MenuItem key="zoomin2fluidtopics" onClick={() => setOpenZoominTaskDialog(true)}>
                            Zoomin Upload
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>

          <Grid item xs={12} className="pt-4">
            <Box sx={{ boxShadow: 0 }}>
              <TextField
                onChange={event => setSearchValue(event.target.value)}
                placeholder="Filter tasks"
                InputProps={{
                  style: {
                    background: 'rgba(51, 51, 51, 0.05)',
                    paddingBottom: '2.5px',
                    paddingTop: '2.5px',
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <HiOutlineSearch style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                }}
                value={searchValue}
                variant="outlined"
                size="small"
                id="search"
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} style={{ margin: '0px -8px 0px -8px' }}>
              {TYPES_FILTER.map(key => (
                <Grid item key={key}>
                  <Button
                    color={filters.types && filters.types.includes(key) ? 'primary' : 'default'}
                    style={{ background: 'rgba(51, 51, 51, 0.05)' }}
                    onClick={() => onClickSelectType(key)}
                    className="status-button"
                    variant="contained"
                  >
                    {key === 'Conversion' ? CONVERSION_TITLE : key}
                  </Button>
                </Grid>
              ))}

              {filtersLength > 0 && (
                <Grid item>
                  <Button
                    className="status-button"
                    variant="contained"
                    color="primary"
                  >
                    {`${filtersLength} filters`}
                    <IconButton
                      onClick={() => setFilters(filters.types ? { types: filters.types } : {})}
                      className="contained-button-icon"
                      style={{ margin: '0 0 0 8px' }}
                    >
                      <MdClose size={18} />
                    </IconButton>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div style={{ paddingLeft: '60px', paddingRight: '60px' }}>
        <Grid container>
          <Grid item xs={12}>
            <RyffineTable
              tableContainerProps={{ style: { paddingTop: '24px' } }}
              initSorting={{ order: 'desc', field: 'created' }}
              localStorageTableKey="tasks-table-state"
              pageLoading={pageLoading}
              resetPageOnSorting
              columns={columns}
              loading={loading}
              rows={tableRows}
            />
          </Grid>
        </Grid>
      </div>

      <FiltersDialog
        onClose={() => setOpenFiltersDialog(false)}
        onSubmit={values => setFilters(values)}
        open={openFiltersDialog}
        init={filters}
      />

      {openNormalizationTaskDialog && (
        <CreateNormalizationDialog
          rerunTask={{}}
          open={openNormalizationTaskDialog}
          onClose={link => handleCreationForm(setOpenNormalizationTaskDialog, link)}
        />
      )}

      {openCreateTaskDialog && (
        <CreateConversionDialog
          rerunTask={{}}
          open={openCreateTaskDialog}
          onClose={link => handleCreationForm(setOpenCreateTaskDialog, link)}
        />
      )}

      {openPandocTaskDialog && (
        <CreatePandocDialog
          rerunTask={{}}
          open={openPandocTaskDialog}
          onClose={link => handleCreationForm(setOpenPandocTaskDialog, link)}
        />
      )}

      {openPaligoTaskDialog && (
        <CreatePaligoDialog
          rerunTask={{}}
          open={openPaligoTaskDialog}
          onClose={link => handleCreationForm(setOpenPaligoTaskDialog, link)}
        />
      )}

      {openIxiasoftTaskDialog && (
        <CreateIxiasoftDialog
          rerunTask={{}}
          open={openIxiasoftTaskDialog}
          onClose={link => handleCreationForm(setOpenIxiasoftTaskDialog, link)}
        />
      )}

      {openSdlTaskDialog && (
        <CreateSdlDialog
          rerunTask={{}}
          open={openSdlTaskDialog}
          onClose={link => handleCreationForm(setOpenSdlTaskDialog, link)}
        />
      )}

      {openFluidTopicsTaskDialog && (
        <CreateFluidTopicsDialog
          rerunTask={{}}
          open={openFluidTopicsTaskDialog}
          onClose={link => handleCreationForm(setOpenFluidTopicsTaskDialog, link)}
        />
      )}

      {openGitHubTaskDialog && (
        <CreateGitHubDialog
          rerunTask={{}}
          open={openGitHubTaskDialog}
          onClose={link => handleCreationForm(setOpenGitHubTaskDialog, link)}
        />
      )}

      {openKionSdlTaskDialog && (
        <CreateKionSdlDialog
          rerunTask={{}}
          open={openKionSdlTaskDialog}
          onClose={link => handleCreationForm(setOpenKionSdlTaskDialog, link)}
        />
      )}

      {openZoominTaskDialog && (
        <CreateZoominDialog
          rerunTask={{}}
          open={openZoominTaskDialog}
          onClose={link => handleCreationForm(setOpenZoominTaskDialog, link)}
        />
        // <CreateZoominDialog
        //   rerunTask={{}}
        //   open={openZoominTaskDialog}
        //   onClose={link => handleCreationForm(setOpenZoominTaskDialog, link)}
        // />
      )}

      {/* <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={(
          <Typography style={{ fontSize: '14px' }}>
            Task is created successfully!
            <span // eslint-disable-line
              onClick={() => history.push(createTaskSnackbarLink)}
              style={{
                textDecoration: 'underline',
                marginLeft: '75px',
                fontWeight: 700,
              }}
            >
              View
            </span>
          </Typography>
        )}
        onClose={() => {
          setCreateTaskSnackbarLink(undefined);
          setCreateTaskSnackbarOpen(false);
        }}
        open={createTaskSnackbarOpen}
      /> */}
    </>
  );
}

export default Tasks;
